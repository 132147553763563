import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { Box } from "../core"
import { ProductCard } from "./product-card"

export function ProductList({ products }) {
  return (
    <ListWrapper>
      {products.map((product) => (
        <ProductCard product={product} key={product.id} />
      ))}
    </ListWrapper>
  )
}

const ListWrapper = styled(Box).attrs(() => ({
  as: "ul",
}))`
  display: grid;
  grid-gap: 32px;

  @media (min-width: ${themeGet("breakpoints.md")}) {
    grid-template-columns: 1fr 1fr;
  }
`
