import { graphql } from "gatsby"
import React from "react"
import { Box } from "../components/core"
import { Layout } from "../components/layout"
import { ProductList } from "../components/product/product-list"
import { SEO } from "../components/seo"
import { filterOutDocsWithoutSlugs, mapEdgesToNodes } from "../lib/helpers"

export const pageQuery = graphql`
  query ProductsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    reviewedProducts: allSanityProduct(
      sort: { order: DESC, fields: _updatedAt }
      filter: { status: { eq: "reviewed" } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          short_description
          status
          colorPrimary
          colorSecondary
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }

    inReviewProducts: allSanityProduct(
      sort: { order: DESC, fields: _updatedAt }
      filter: { status: { eq: "inReview" } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          short_description
          status
          colorPrimary
          colorSecondary
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
    }
  }
`

export default function ProductPage({ data: { site, reviewedProducts, inReviewProducts } }) {
  const { description, keywords } = site

  const reviewedProductNodes = mapEdgesToNodes(reviewedProducts).filter(filterOutDocsWithoutSlugs)
  const inReviewProductNodes = mapEdgesToNodes(inReviewProducts).filter(filterOutDocsWithoutSlugs)

  return (
    <Layout>
      <SEO title="Products" description={description} keywords={keywords} />
      <Box as="h1" textAlign="center" mb={["80px"]}>
        Products
      </Box>
      <ProductList products={reviewedProductNodes} />
      <Box as="h3" textAlign="center" mt={["120px"]} mb={["40px"]}>
        Upcoming
      </Box>
      <ProductList products={inReviewProductNodes} />
    </Layout>
  )
}
